import Vue from 'vue';
import Vuex from 'vuex-typescript-interface';

import { RootStoreOptions, IStore } from './types';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

const storeOptions: RootStoreOptions = {
  state,
  getters,
  mutations,
  actions,
  strict: true,
};

export default new Vuex.Store<IStore>(storeOptions);
