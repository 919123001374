





















































import Vue from 'vue';
import LazyYoutubeVideo from 'vue-lazy-youtube-video/dist/vue-lazy-youtube-video.esm';

import Modal from '@/components/modal.vue';

export default Vue.extend({
  name: 'GuideOverlay',
  components: {
    Modal,
    LazyYoutubeVideo,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    listItems: {label: string, video: string }[],
    activeListItemId: number,
  } {
    return {
      listItems: [
        {
          label: 'Select sales & marketing model',
          video: 'https://www.youtube.com/embed/SpbTih0A_ys',
        },
        {
          label: 'Input your own data',
          video: 'https://www.youtube.com/embed/zd-ad6_Y9YE',
        },
        {
          label: 'Use tooltips for more information',
          video: 'https://www.youtube.com/embed/c7NiVQF1q0Q',
        },
        {
          label: 'Custom spend recommendations',
          video: 'https://www.youtube.com/embed/6ZDqjjKRCSE',
        },
        // {
        //   label: 'Get ready for mega profits',
        //   video: 'https://www.youtube.com/embed/bP9kC6dij-Q',
        // },
      ],
      activeListItemId: 0,
    };
  },
  methods: {
    handleKeyup(event: KeyboardEvent) {
      if (event.defaultPrevented) {
        return;
      }

      if (event.altKey || event.shiftKey || event.ctrlKey || event.metaKey) {
        return;
      }

      if (
        event.code === 'Escape' ||
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27
      ) {
        event.preventDefault();
        this.close();
      }
    },
    close() {
      if (!this.isOpen) {
        return;
      }

      this.$emit('close');
    },
  },
  beforeDestroy() {
    // Clean up if global event listener and body class are added
    document.removeEventListener('keyup', this.handleKeyup);
    document.body.classList.remove('u-overflow-hidden');
  },
  watch: {
    isOpen: {
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }

        if (val) {
          document.body.classList.add('u-overflow-hidden');
          document.addEventListener('keyup', this.handleKeyup);
        } else {
          document.removeEventListener('keyup', this.handleKeyup);
          document.body.classList.remove('u-overflow-hidden');
        }
      },
      immediate: true,
    },
  },
});
