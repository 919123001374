/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-youtube': {
    width: 24,
    height: 16.8,
    viewBox: '0 0 24 16.8',
    data: '<path pid="0" d="M23.5 2.62A3 3 0 0021.38.5C19.51 0 12 0 12 0S4.49 0 2.62.5A3 3 0 00.5 2.62 31.34 31.34 0 000 8.4a31.34 31.34 0 00.5 5.78 3 3 0 002.12 2.12c1.87.5 9.38.5 9.38.5s7.51 0 9.38-.5a3 3 0 002.12-2.12A31.34 31.34 0 0024 8.4a31.34 31.34 0 00-.5-5.78zM9.6 12V4.8l6.24 3.6z"/>'
  }
})
