/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-pinterest': {
    width: 15.56,
    height: 20,
    viewBox: '0 0 15.56 20',
    data: '<path pid="0" d="M6.4 13.23C5.87 16 5.23 18.62 3.33 20c-.55-4.16.86-7.29 1.53-10.6C3.72 7.47 5 3.58 7.42 4.54c3 1.18-2.58 7.17 1.15 7.92 3.89.78 5.48-6.75 3.06-9.2-3.48-3.53-10.14-.08-9.32 5 .2 1.22 1.47 1.6.47 3.31-2.23-.5-2.9-2.26-2.81-4.6A7.51 7.51 0 016.78.07c4.2-.47 8.14 1.54 8.69 5.49.61 4.46-1.9 9.3-6.39 9-1.22-.1-1.73-.7-2.68-1.28z"/>'
  }
})
