








import Vue from 'vue';
import ModalContent from '@/components/modal-content.vue';

export default Vue.extend({
  components: {
    ModalContent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
