import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

/**
 * Views
 */
import Home from '@/views/home.vue';
import PrivacyPolicy from '@/views/privacy-policy.vue';

Vue.use(Router);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
];

/**
 * Main route definition
 */
const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.meta && to.meta.keepScrollPosition) {
      return;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    }

    return { x: 0, y: 0 };
  },
});

export default router;
