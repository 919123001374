/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dollar': {
    width: 14,
    height: 24,
    viewBox: '0 0 14 24',
    data: '<path pid="0" d="M9.5 11H8V6h4a1 1 0 000-2H8V1a1 1 0 00-2 0v3H4.5a4.5 4.5 0 000 9H6v5H1a1 1 0 000 2h5v3a1 1 0 002 0v-3h1.5a4.5 4.5 0 000-9zm-5 0a2.5 2.5 0 010-5H6v5zm5 7H8v-5h1.5a2.5 2.5 0 010 5z"/>'
  }
})
