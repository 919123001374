/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'percentage': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16.71 1.29a1 1 0 00-1.42 0l-14 14a1 1 0 000 1.42 1 1 0 001.42 0l14-14a1 1 0 000-1.42zM3.5 7A3.5 3.5 0 100 3.5 3.5 3.5 0 003.5 7zm0-5A1.5 1.5 0 112 3.5 1.5 1.5 0 013.5 2zM14.5 11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z"/>'
  }
})
