export enum InputFieldTypes {
  Decimal = 'decimal',
  Percent = 'percent',
  Currency = 'currency',
}

export interface SelectorOption {
  id: number;
  label: string;
  description?: string;
}
