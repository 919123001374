/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'help': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M11 0a11 11 0 1011 11A11 11 0 0011 0zm0 20a9 9 0 119-9 9 9 0 01-9 9z"/><path pid="1" d="M12.25 5.22a4 4 0 00-3.06.17 4 4 0 00-2 2.28A1 1 0 109 8.33a2 2 0 013.89.67c0 1-1.72 1.85-2.32 2.05a1 1 0 00-.57 1.27 1 1 0 00.95.68 1.25 1.25 0 00.32-.05c.37-.13 3.68-1.31 3.68-3.95a4 4 0 00-2.7-3.78zM11 15a1 1 0 101 1 1 1 0 00-1-1z"/>'
  }
})
