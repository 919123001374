














































































































































































































































































































import Vue from 'vue';

import { validateEmail } from '@/utils/email';

import { mapState, mapGetters, mapActions } from '@/store/helpers';
import { growthOptions, smModelOptions } from '@/store/types';

import BudgetSelector from '@/components/budget-selector.vue';
import Selector from '@/components/selector.vue';
import InputField from '@/components/input-field.vue';
import OutputFormatting from '@/utils/mixins/formatting';
import { InputFieldTypes, SelectorOption } from '@/components/types.ts';
import Icon from '@/components/icon.vue';
import SiteFooter from '@/components/site-footer.vue';
import GuideOverlay from '@/components/guide-overlay.vue';
import axios from 'axios';

export default Vue.extend({
  mixins: [OutputFormatting],
  components: {
    BudgetSelector,
    Selector,
    InputField,
    Icon,
    SiteFooter,
    GuideOverlay,
  },
  data(): {
    isMobileSize: boolean,
    isPreviewActive: boolean,
    growthOptions: SelectorOption[],
    modelOptions: SelectorOption[],
    email: string,
    isSubmitted: boolean,
    viewportHeight: number,
    isGuideOverlayOpen: boolean,
  } {
    return {
      isMobileSize: false,
      isPreviewActive: false,
      growthOptions: [
        // {
        //   id: growthOptions.Hubspot,
        //   label: 'Hubspot',
        //   description: 'Choosing this selection will model your CAC to ACV ratio like Hubspots based on their S1',
        // },
        {
          id: growthOptions.AverageCompany,
          label: 'Average SaaS Company (35% growth)',
          description: 'Choosing this selection will model your CAC to ACV ratio like the average SaaS company based on industry averages',
        },
      ],
      modelOptions: [
        {
          id: smModelOptions.FieldSales,
          label: 'Field Sales',
          description: 'Majority of deals come from sales team that is not in the office.',
        },
        {
          id: smModelOptions.InsideSales,
          label: 'Inside Sales',
          description: 'Majority of the sales team is inside, and driven by inbound sales.'
        }
      ],
      isSubmitted: false,
      email: '',
      viewportHeight: 0,
      isGuideOverlayOpen: false,
    };
  },
  computed: {
    ...mapState({
      selectedGrowthModel: 'selectedGrowthModel',
      selectedSalesModel: 'selectedSalesModel',
      dealSize: 'dealSize',
      expectedChurnPercentage: 'expectedChurnPercentage',
      salesRevenueGrowthPercentage: 'salesRevenueGrowthPercentage',
      grossProfitPercentage: 'grossProfitPercentage',
      ARRCurrent: 'ARRCurrent',
      ARRGoal: 'ARRGoal',
      paidChannelCustomersPercentage: 'paidChannelCustomersPercentage',
      marketingPeopleSpendPercentage: 'marketingPeopleSpendPercentage',
      marketingTechSpendPercentage: 'marketingTechSpendPercentage',
      marketingPromotionsSpendPercentage: 'marketingPromotionsSpendPercentage',
    }),
    ...mapGetters({
      avgCAC: 'avgCAC',
      avgMarketingSpendPercentage: 'avgMarketingSpendPercentage',
      expansionRevenueGrowthPercentage: 'expansionRevenueGrowthPercentage',
      salesGoal: 'salesGoal',
      projectedSMBudget: 'projectedSMBudget',
      avgSMBudget: 'avgSMBudget',
      neededCustomers: 'neededCustomers',
      newCustomerCAC: 'newCustomerCAC',
      blendedSpendRevenuePercentage: 'blendedSpendRevenuePercentage',
      marketingPromotionsSpend: 'marketingPromotionsSpend',
      marketingPeopleSpend: 'marketingPeopleSpend',
      marketingTechSpend: 'marketingTechSpend',
      paidChannelCAC: 'paidChannelCAC',
    }),
    spendEstimateReady() {
      return (parseFloat(this.avgSMBudget) > 0);
    },
    inputFieldTypes() {
      // Helper to carry over TS enum
      return { ...InputFieldTypes };
    },
  },
  methods: {
    ...mapActions({
      changeSelectedGrowthModel: 'changeSelectedGrowthModel',
      changeSelectedSalesModel: 'changeSelectedSalesModel',
      changeDealSize: 'changeDealSize',
      changeExpectedChurnPercentage: 'changeExpectedChurnPercentage',
      changeSalesRevenueGrowthPercentage: 'changeSalesRevenueGrowthPercentage',
      changeGrossProfitPercentage: 'changeGrossProfitPercentage',
      changeARRCurrent: 'changeARRCurrent',
      changeARRGoal: 'changeARRGoal',
      changePaidChannelCustomersPercentage: 'changePaidChannelCustomersPercentage',
      changeMarketingPeopleSpendPercentage: 'changeMarketingPeopleSpendPercentage',
      changeMarketingTechSpendPercentage: 'changeMarketingTechSpendPercentage',
      changeMarketingPromotionsSpendPercentage: 'changeMarketingPromotionsSpendPercentage',
    }),
    handleBudgetSelectorChange(input: { people: number; tech: number; promotion: number; }) {
      this.changeMarketingPeopleSpendPercentage(input.people);
      this.changeMarketingTechSpendPercentage(input.tech);
      this.changeMarketingPromotionsSpendPercentage(input.promotion);
    },
    handleWindowResize() {
      const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

      this.viewportHeight = height;
      this.isMobileSize = width <= 769;
    },
    openPreview() {
      if (this.isMobileSize && !this.isPreviewActive) {
        document.body.classList.add('u-overflow-hidden');
        this.isPreviewActive = true;
      }
    },
    closePreview() {
      if (this.isMobileSize && this.isPreviewActive) {
        document.body.classList.remove('u-overflow-hidden');
        this.isPreviewActive = false;
      }
    },
    togglePreview() {
      if (!this.isMobileSize) {
        return;
      }

      if (this.isPreviewActive) {
        this.closePreview();
      } else {
        this.openPreview();
      }
    },
    stopPreviewContentPropagation(event: MouseEvent) {
      if (this.isPreviewActive) {
        return;
      }

      event.stopPropagation();
    },
    submitForm() {
      if (!this.email.trim()) {
        window.alert('Email is required!');
        return;
      }

      if (!validateEmail(this.email)) {
        window.alert('Your email is invalid!');
        return;
      }

      if (!(window as any).dataLayer) {
        window.alert('Invalid request! Please reload page and try again.');
        return;
      }

      const growthModel = (Array.from(this.growthOptions) as SelectorOption[]).find(({id}) => id === this.selectedGrowthModel);
      const smModel = (Array.from(this.modelOptions) as SelectorOption[]).find(({id}) => id === this.selectedSalesModel);

      if (!growthModel) {
        window.alert('Please select how you want to grow.');
        return;
      }

      if (!smModel) {
        window.alert('Please select your S&M model.');
        return;
      }

      (window as any).dataLayer.push({
        email: this.email,
        dealSize: this.dealSize,
        currentArr: this.ARRCurrent,
        goalArr: this.ARRGoal,
        growthModel: growthModel.label,
        smModel: smModel.label,
        event: 'contact_request',
      });

      let host = 'www.convertiv.com';

      if (window.location.hostname !== 'calculator.convertiv.com') {
        host = 'convertivstg.wpengine.com';
      }

      axios.post('https://' + host + '/wp-json/calculator/v1/form/', {
        // Personal Information
        email: this.email,
        // Revenue
        totalARRGoal: this.formatCurrency(this.ARRGoal),
        baseARR: this.formatCurrency(this.ARRCurrent),
        // Company Characteristics
        averageDealSizeInARR: this.formatCurrency(this.dealSize),
        cacNewCustomerRevenue: this.formatDecimal(this.avgCAC),
        smSpendForMarketing: this.formatPercentage(this.avgMarketingSpendPercentage),
        // Average Ratios
        expectedChurn: this.formatPercentage(this.expectedChurnPercentage),
        grossProfit: this.formatPercentage(this.grossProfitPercentage),
        revenueGrowthFromNewSales: this.formatPercentage(this.salesRevenueGrowthPercentage),
        revenueGrowthFromCrossSellUpsell: this.formatPercentage(this.expansionRevenueGrowthPercentage),
        // Growth Goals
        netNewSalesGoal: this.formatCurrency(this.salesGoal),
        // Output
        marketingSpendRequired: this.formatCurrency(this.avgSMBudget),
        marketingCAConNewCustomers: this.formatCurrency(this.newCustomerCAC),
        newCustomersNeeded: this.formatDecimal(this.neededCustomers),
        marketingSpendRevenueRatio: this.formatPercentage(this.blendedSpendRevenuePercentage),
        // Spend Breakdown
        headCount: this.formatCurrency(this.marketingPeopleSpend) + '(' + this.formatPercentage(this.marketingPeopleSpendPercentage) + ')',
        techStack: this.formatCurrency(this.marketingTechSpend) + '(' + this.formatPercentage(this.marketingTechSpendPercentage) + ')',
        promotions: this.formatCurrency(this.marketingPromotionsSpend) + '(' + this.formatPercentage(this.marketingPromotionsSpendPercentage) + ')',
      }, {
        headers: {
          // remove headers
        }
      });

      this.email = '';
      this.isSubmitted = true;
    },
  },
  mounted() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('deviceorientation', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('deviceorientation', this.handleWindowResize);
  },
  watch: {
    isMobileSize(val) {
      if (val) {
        document.body.classList.add('has-sticky-card');
      } else {
        document.body.classList.remove('has-sticky-card');
        document.body.classList.remove('u-overflow-hidden');
      }
    },
  },
});
