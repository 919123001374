/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-instagram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.67 0H7.33A7.33 7.33 0 000 7.33v9.34A7.33 7.33 0 007.33 24h9.34A7.33 7.33 0 0024 16.67V7.33A7.33 7.33 0 0016.67 0zM22 16.67A5.33 5.33 0 0116.67 22H7.33A5.33 5.33 0 012 16.67V7.33A5.33 5.33 0 017.33 2h9.34A5.33 5.33 0 0122 7.33z"/><path pid="1" d="M12 6a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z"/><circle pid="2" cx="18.27" cy="5.73" r="1.33"/>'
  }
})
