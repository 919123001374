/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-feedly': {
    width: 24,
    height: 21.24,
    viewBox: '0 0 24 21.24',
    data: '<path pid="0" d="M7.4 20.55L.62 13.73a2.62 2.62 0 010-3.39L10.33.62a2.65 2.65 0 013.3 0l9.75 9.76a2.62 2.62 0 010 3.39l-6.78 6.78a2.42 2.42 0 01-1.69.69H9a2.28 2.28 0 01-1.6-.69zm6.18-2.65a.41.41 0 000-.55l-1.33-1.33a.41.41 0 00-.55 0l-1.33 1.33a.44.44 0 000 .55l1.06 1h1.1l1.05-1zm0-5.64a.35.35 0 000-.41l-1.42-1.42a.35.35 0 00-.41 0l-4.12 4.12a.48.48 0 000 .6l1 1h1.1l3.89-3.85zm0-5.58c.09-.1.14-.37 0-.46l-1.37-1.38a.51.51 0 00-.51 0l-6.91 6.92a.41.41 0 000 .46l1.1 1.05h1l6.69-6.59z"/>'
  }
})
