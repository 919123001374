/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-facebook': {
    width: 11.15,
    height: 24,
    viewBox: '0 0 11.15 24',
    data: '<path pid="0" d="M11.15 7.77h-3.8V5.28a1 1 0 011.06-1.15h2.68V0H7.4c-4.1 0-5 3.07-5 5v2.77H0V12h2.37v12h5V12h3.37z" fill-rule="evenodd"/>'
  }
})
