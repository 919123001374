/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-slide': {
    width: 36,
    height: 10.13,
    viewBox: '0 0 36 10.13',
    data: '<path pid="0" d="M18 5.25L3.27 10a2.5 2.5 0 01-1.54-4.75L18 0l16.27 5.25A2.5 2.5 0 0132.73 10z"/>'
  }
})
