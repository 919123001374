















import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    scale: {
      type: String,
      default: undefined,
    },
    dir: {
      type: String,
      default: undefined,
    },
    fill: {
      type: Boolean,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    original: {
      type: Boolean,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
});
