/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M20.71 1.29a1 1 0 00-1.42 0L10 10.59l-2.29-2.3a1.004 1.004 0 00-1.42 1.42l3 3a1 1 0 001.42 0l10-10a1 1 0 000-1.42z"/><path pid="1" d="M19 9a1 1 0 00-1 1v7a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h11a1 1 0 000-2H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-7a1 1 0 00-1-1z"/>'
  }
})
