import { MutationTree } from 'vuex-typescript-interface';
import { IStore } from './types';

export default {
  setSelectedGrowthModel(state, value) {
    state.selectedGrowthModel = value;
  },
  setSelectedSalesModel(state, value) {
    state.selectedSalesModel = value;
  },
  setDealSize(state, value) {
    state.dealSize = value;
  },
  setExpectedChurnPercentage(state, value) {
    state.expectedChurnPercentage = value;
  },
  setSalesRevenueGrowthPercentage(state, value) {
    state.salesRevenueGrowthPercentage = value;
  },
  setGrossProfitPercentage(state, value) {
    state.grossProfitPercentage = value;
  },
  setARRCurrent(state, value) {
    state.ARRCurrent = value;
  },
  setARRGoal(state, value) {
    state.ARRGoal = value;
  },
  setPaidChannelCustomersPercentage(state, value) {
    state.paidChannelCustomersPercentage = value;
  },
  setMarketingPeopleSpendPercentage(state, value) {
    state.marketingPeopleSpendPercentage = value;
  },
  setMarketingTechSpendPercentage(state, value) {
    state.marketingTechSpendPercentage = value;
  },
  setMarketingPromotionsSpendPercentage(state, value) {
    state.marketingPromotionsSpendPercentage = value;
  },
} as MutationTree<IStore>;
