// Reusable formatting methods

export const outputFormatting = {
  methods: {
    formatCurrency(value: number): string {
      if (isNaN(value)) {
        return '-';
      }
      return value.toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: true,
        maximumFractionDigits: 0,
      });
    },
    formatDecimal(value: number): string {
      if (isNaN(value)) {
        return '-';
      }
      return value.toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: false,
        maximumFractionDigits: 2,
      });
    },
    formatPercentage(value: number): string {
      if (isNaN(value)) {
        return '-';
      }
      value = value * 100;
      return value.toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: false,
        maximumFractionDigits: 0,
      });
    },
  },
};

export default outputFormatting;
