/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'graph': {
    width: 24,
    height: 14,
    viewBox: '0 0 24 14',
    data: '<path pid="0" d="M23.92.62a1 1 0 00-.54-.54A1 1 0 0023 0h-6a1 1 0 000 2h3.59L13.5 9.09l-4.29-4.3a1 1 0 00-1.42 0l-7.5 7.5a1 1 0 000 1.42 1 1 0 001.42 0l6.79-6.8 4.29 4.3a1 1 0 001.42 0L22 3.41V7a1 1 0 002 0V1a1 1 0 00-.08-.38z"/>'
  }
})
