import { StoreOptions } from 'vuex-typescript-interface';

export enum growthOptions {
  Unselected,
  Hubspot,
  AverageCompany,
}

export enum smModelOptions {
  Unselected,
  InsideSales,
  InternetSales,
  FieldSales,
  ChannelSales,
  Mixed,
}

export interface RootState {
  selectedGrowthModel: growthOptions;
  selectedSalesModel: smModelOptions;
  dealSize: number;
  expectedChurnPercentage: number;
  salesRevenueGrowthPercentage: number;
  grossProfitPercentage: number;
  ARRCurrent: number;
  ARRGoal: number;
  paidChannelCustomersPercentage: number;
  marketingPeopleSpend: number;
  marketingTechSpend: number;
  marketingPeopleSpendPercentage: number;
  marketingTechSpendPercentage: number;
  marketingPromotionsSpendPercentage: number;
}

export interface RootGetters {
  readonly avgCAC: number;
  readonly avgMarketingSpendPercentage: number;
  readonly expansionRevenueGrowthPercentage: number;
  readonly getExpectedChurnPercentage: number;
  readonly expectedChurn: number;
  readonly salesGoal: number;
  readonly projectedSMBudget: number;
  readonly avgSMBudget: number;
  readonly neededCustomers: number;
  readonly newCustomerCAC: number;
  readonly blendedSpendRevenuePercentage: number;
  readonly paidChannelCAC: number;
  readonly marketingPromotionsSpend: number;
  readonly marketingPeopleSpend: number;
  readonly marketingTechSpend: number;
}

export interface RootMutations {
  setSelectedGrowthModel(value: number): void,
  setSelectedSalesModel(value: number): void,
  setDealSize(value: number): void,
  setExpectedChurnPercentage(value: number): void,
  setSalesRevenueGrowthPercentage(value: number): void,
  setGrossProfitPercentage(value: number): void,
  setARRCurrent(value: number): void,
  setARRGoal(value: number): void,
  setPaidChannelCustomersPercentage(value: number): void,
  setMarketingPeopleSpendPercentage(value: number): void,
  setMarketingTechSpendPercentage(value: number): void,
  setMarketingPromotionsSpendPercentage(value: number): void,
}

export interface RootActions {
  changeSelectedGrowthModel(value: number): Promise<void>,
  changeSelectedSalesModel(value: number): Promise<void>,
  changeDealSize(value: number): Promise<void>,
  changeExpectedChurnPercentage(value: number): Promise<void>,
  changeSalesRevenueGrowthPercentage(value: number): Promise<void>,
  changeGrossProfitPercentage(value: number): Promise<void>,
  changeARRCurrent(value: number): Promise<void>,
  changeARRGoal(value: number): Promise<void>,
  changePaidChannelCustomersPercentage(value: number): Promise<void>,
  changeMarketingPeopleSpendPercentage(value: number): Promise<void>,
  changeMarketingTechSpendPercentage(value: number): Promise<void>,
  changeMarketingPromotionsSpendPercentage(value: number): Promise<void>,
}

export type IStore = RootState &
  RootGetters &
  RootMutations &
  RootActions;

export type RootStoreOptions = StoreOptions<IStore>;
