import 'vue-svgicon/dist/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as svgicon from 'vue-svgicon';
import './sass/main.scss';
import 'vue-lazy-youtube-video/dist/style.css';
import config from '@/config';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import '@/icons';

Vue.config.productionTip = false;
Vue.use(svgicon, {
  tagName: 'svgicon',
  classPrefix: 'o-svg',
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

if (process.env.VUE_APP_MODE !== 'development') {
  Raven.config('https://edf74c39164e4e209e4ed19083a320f8@sentry.io/1760185', {
    release: config.BUILD,
    environment: process.env.VUE_APP_MODE,
  })
  .addPlugin(RavenVue, Vue)
  .install();
}
