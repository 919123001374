/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.5.5a1.72 1.72 0 012.43 0L12 9.57 21.07.5a1.72 1.72 0 012.27-.14l.16.14a1.72 1.72 0 010 2.43L14.43 12l9.07 9.07a1.72 1.72 0 01.14 2.27l-.14.16a1.72 1.72 0 01-2.43 0L12 14.43 2.93 23.5a1.72 1.72 0 01-2.27.14L.5 23.5a1.72 1.72 0 010-2.43L9.57 12 .5 2.93A1.72 1.72 0 01.36.66z" fill-rule="evenodd"/>'
  }
})
