import {
  createHelpers,
  GetterKeys,
  Dispatch,
  createNamespacedHelpers,
} from 'vuex-typescript-interface';

import { IStore } from '@/store/types';
import store from './index';

export const createModuleVuexHelpers = <M>(namespace: string): {
  dispatch: Dispatch<M, IStore>,
  getters: <K extends GetterKeys<M>> (key: K) => M[K],
} => {
  return {
    dispatch: ((type: never, payload: never, options: never) => {
      return store.dispatch(`${namespace}/${type}` as never, payload as never, options) as never;
    }) as never,
    getters: ((key: never) => {
      return store.getters[`${namespace}/${key}` as never];
    }) as never,
  };
};

export const createModuleHelpers = <M>(namespace: string) => {
  return {
    ...createNamespacedHelpers<M, IStore>(namespace),
    ...createModuleVuexHelpers<M>(namespace),
  };
};

const { mapState, mapGetters, mapActions } = createHelpers<IStore>();
export {
  mapState,
  mapGetters,
  mapActions,
};

export const dispatch = store.dispatch;
export const getters = <K extends GetterKeys<IStore>>(key: K): IStore[K] => store.getters[key];
