























import Vue, { PropOptions } from 'vue';
import { SelectorOption } from '@/components/types.ts';

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    } as PropOptions<SelectorOption[]>,
    selected: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    hasSingleOption() {
      return this.options.length === 1;
    },
    selectedLabel() {
      const defaultLabel: string = 'Select one...';

      if (this.hasSingleOption) {
        return this.options[0].label;
      }

      if (this.isActive) {
        return defaultLabel;
      }

      for (const option of this.options) {
        if (option.id === this.selected) {
          return option.label;
        }
      }

      return defaultLabel;
    },
  },
  methods: {
    toggleActive() {
      this.data.isActive = !this.data.isActive;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isActive = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
});
