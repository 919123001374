/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rotate': {
    width: 22,
    height: 20,
    viewBox: '0 0 22 20',
    data: '<path pid="0" d="M22 8.14A.7.7 0 0022 8V2a1 1 0 00-2 0v3.68l-2.92-2.75A10.16 10.16 0 0013.33.57a10 10 0 106.1 12.76 1 1 0 10-1.88-.66 8 8 0 01-10.21 4.87 8 8 0 115.33-15.08 8 8 0 013 1.91L18.48 7H15a1 1 0 000 2h6a1 1 0 00.24-.05h.14l.1-.07a1.12 1.12 0 00.22-.16s0-.08.07-.12a.86.86 0 00.13-.2 1.85 1.85 0 00.1-.26z"/>'
  }
})
