



































































import Vue, { PropOptions } from 'vue';
import debounce from 'lodash/debounce';

enum HandleType {
  People,
  Tech,
  Promotion,
}

interface BudgetSelectorValue {
  people: number;
  tech: number;
  promotion: number;
}

const preventDefault = (event) => event.preventDefault();

export default Vue.extend({
  props: {
    value: {
      type: Object,
      required: true,
    } as PropOptions<BudgetSelectorValue>,
  },
  data(): {
    startOffset: number,
    draggingHandle: false|HandleType,
    draggingStartAngle: number,
    draggingStartValue?: BudgetSelectorValue,
    draggingStartOffset?: number,
    elCenterX: number,
    elCenterY: number,
    HandleType: typeof HandleType,
  } {
    return {
      startOffset: 0,
      draggingHandle: false,
      draggingStartAngle: -1,
      draggingStartValue: undefined,
      draggingStartOffset: undefined,
      elCenterX: -1,
      elCenterY: -1,
      HandleType,
    };
  },
  computed: {
    isDragging(): boolean {
      return this.draggingHandle !== false;
    },
    /* People */
    peopleSliceStartAngle(): number {
      return this.startOffset;
    },
    drawPeopleSlice(): string {
      return this.describeSlice(this.value.people, this.peopleSliceStartAngle);
    },
    drawPeopleHandle(): string {
      return this.transformHandle(this.techSliceStartAngle);
    },

    /* Tech */
    techSliceStartAngle(): number {
      return this.percentToPolar(this.value.people, this.startOffset);
    },
    drawTechSlice(): string {
      return this.describeSlice(this.value.tech, this.techSliceStartAngle);
    },
    drawTechHandle(): string {
      return this.transformHandle(this.promotionSliceStartAngle);
    },

    /* Promotion */
    promotionSliceStartAngle(): number {
      return this.percentToPolar(this.value.tech + this.value.people, this.startOffset);
    },
    drawPromotionSlice(): string {
      return this.describeSlice(this.value.promotion, this.promotionSliceStartAngle);
    },
    drawPromotionHandle(): string {
      return this.transformHandle(this.peopleSliceStartAngle);
    },
  },
  methods: {
    normalizeAngle(angle: number): number {
      return (angle % 360 + 360) % 360;
    },
    angleIsBetween(angle, min, max): boolean {
      if (max < min) {
        return angle >= min || angle <= max;
      }

      return angle >= min && angle <= max;
    },
    restrictAngle(angle: number, min: number, max: number): number {
      if (
        // Do not restrict angle if other two angles are equal
        min === max ||
        this.angleIsBetween(angle, min, max)
      ) {
        return angle;
      }

      const minDiff = this.anglesDifference(angle, min);
      const maxDiff = this.anglesDifference(max, angle);

      if (minDiff < maxDiff) {
        return min;
      }

      return max;
    },
    anglesDifference(startAngle: number, endAngle: number): number {
      if (endAngle < startAngle) {
        return endAngle + 360 - startAngle;
      }

      return endAngle - startAngle;
    },
    polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
      const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians)),
      };
    },
    cartesianToPolar(x: number, y: number, centerX: number, centerY: number): number {
      const dx = x - centerX;
      const dy = y - centerY;

      return (180 / Math.PI) * Math.atan2(dy, dx);
    },
    describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = [
            'M', start.x, start.y,
            'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        ].join(' ');

        return d;
    },
    percentToPolar(percentage: number, offsetInDegrees: number = 0): number {
      return offsetInDegrees + percentage * 360;
    },
    describeSlice(value: number, offset: number): string {
      const angle = this.percentToPolar(value, offset);
      return this.describeArc(60, 60, 35, offset, angle);
    },
    transformHandle(angle: number): string {
      angle = angle - 180;
      return 'translate(45,85) rotate(' + angle + ', 15, -25)';
    },
    handleMouseDown(event: MouseEvent, handleType: HandleType) {
      this.startDragging(event, handleType, event.clientX, event.clientY);
    },
    handleTouchStart(event: TouchEvent, handleType: HandleType) {
      this.startDragging(event, handleType, event.touches[0].clientX, event.touches[0].clientY);
    },
    handleMouseMove(event: MouseEvent) {
      this.handleDragging(event.clientX, event.clientY);
    },
    handleTouchMove(event: TouchEvent) {
      event.preventDefault();
      this.handleDragging(event.touches[0].clientX, event.touches[0].clientY);
    },
    startDragging(
      event: TouchEvent|MouseEvent,
      handleType: HandleType,
      startX: number,
      startY: number,
    ) {
      if (this.draggingHandle !== false) {
        return;
      }

      event.preventDefault();

      const isTouchEvent = event.type.startsWith('touch');

      this.adjustElementCenter();

      this.draggingHandle = handleType;
      this.draggingStartAngle = this.cartesianToPolar(
        startX,
        startY,
        this.elCenterX,
        this.elCenterY,
      );
      this.draggingStartOffset = this.startOffset;
      this.draggingStartValue = {...this.value};

      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.stopDragging);
      document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
      document.addEventListener('touchend', this.stopDragging);
      window.addEventListener('scroll', preventDefault);
      document.addEventListener('selectstart', preventDefault);

      if (isTouchEvent) {
        document.addEventListener('wheel', preventDefault);
      }
    },
    stopDragging(event: TouchEvent|MouseEvent) {
      if (this.draggingHandle === false) {
        return;
      }

      const isTouchEvent = event.type.startsWith('touch');

      this.draggingHandle = false;

      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.stopDragging);
      document.removeEventListener('touchmove', this.handleTouchMove);
      document.removeEventListener('touchend', this.stopDragging);
      window.removeEventListener('scroll', preventDefault);
      document.removeEventListener('selectstart', preventDefault);

      if (isTouchEvent) {
        document.removeEventListener('wheel', preventDefault);
      }
    },
    handleDragging(x: number, y: number) {
      if (this.draggingHandle === false) {
        return;
      }

      const angle = this.cartesianToPolar(
        x,
        y,
        this.elCenterX,
        this.elCenterY,
      );

      const dAngle = angle - this.draggingStartAngle;
      const dPercentage = dAngle / 360;

      let peopleSliceStartAngle = this.normalizeAngle(this.draggingStartOffset);
      let techSliceStartAngle = this.normalizeAngle(this.techSliceStartAngle);
      let promotionSliceStartAngle = this.normalizeAngle(this.promotionSliceStartAngle);

      switch (this.draggingHandle) {
        case HandleType.Promotion:
          peopleSliceStartAngle = this.restrictAngle(
            this.normalizeAngle(this.draggingStartOffset + dAngle),
            promotionSliceStartAngle,
            techSliceStartAngle,
          );
          break;
        case HandleType.People:
          techSliceStartAngle = this.restrictAngle(
            this.normalizeAngle(
              this.percentToPolar(this.draggingStartValue.people, this.startOffset) + dAngle,
            ),
            peopleSliceStartAngle,
            promotionSliceStartAngle,
          );
          break;
        case HandleType.Tech:
          promotionSliceStartAngle = this.restrictAngle(
            this.normalizeAngle(
              this.percentToPolar(
                this.draggingStartValue.tech + this.draggingStartValue.people,
                this.startOffset,
              ) + dAngle,
            ),
            techSliceStartAngle,
            peopleSliceStartAngle,
          );
          break;
      }

      this.startOffset = peopleSliceStartAngle;

      // Recalculate percentages with new angles
      this.$emit('input', {
        people: this.anglesDifference(peopleSliceStartAngle, techSliceStartAngle) / 360,
        tech: this.anglesDifference(techSliceStartAngle, promotionSliceStartAngle) / 360,
        promotion: this.anglesDifference(promotionSliceStartAngle, peopleSliceStartAngle) / 360,
      });
    },
    adjustElementCenter() {
      const domRect: DOMRect = this.$el.getBoundingClientRect();
      this.elCenterX = domRect.left + ( domRect.width / 2 );
      this.elCenterY = domRect.top + ( domRect.height / 2 );
    },
    debouncedAdjustElementCenter: debounce(function() {this.adjustElementCenter();}, 100),
  },
  mounted() {
    this.adjustElementCenter();

    window.addEventListener('resize', this.debouncedAdjustElementCenter);
    window.addEventListener('deviceorientation', this.debouncedAdjustElementCenter);
  },
  beforeDestroy() {
    this.stopDragging();

    window.removeEventListener('resize', this.debouncedAdjustElementCenter);
    window.removeEventListener('deviceorientation', this.debouncedAdjustElementCenter);
  },
});
