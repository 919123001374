/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-linkedin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="3" cy="3" r="3"/><path pid="1" d="M5.5 8h-5a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-15a.5.5 0 00-.5-.5zM20.4 7.31A7.6 7.6 0 0014 8.38a.49.49 0 00-.5-.38h-5a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V12.72a2.61 2.61 0 012.7-.56A2.35 2.35 0 0118 14.5v9a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-10c-.06-4.12-2-5.64-3.6-6.19z"/>'
  }
})
