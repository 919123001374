




































import Vue from 'vue';
import OutputFormatting from '@/utils/mixins/formatting';
import { InputFieldTypes } from '@/components/types.ts';
import { PropValidator } from 'vue/types/options';
import Modal from '@/components/modal.vue';

let id = 0;

const getId = () => id++;

export interface TipData {
  label: string,
  description: string,
}

export default Vue.extend({
  components: {
    Modal,
  },
  mixins: [OutputFormatting],
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Enter value',
    },
    label: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      validator: (value: InputFieldTypes) => [InputFieldTypes.Decimal, InputFieldTypes.Percent, InputFieldTypes.Currency].includes(value),
    },
    value: {
      type: Number,
      required: true,
    },
    interactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    tip: {
      type: Object,
    } as PropValidator<TipData>,
    maxDigits: {
      type: Number,
      required: false,
    },
    maxValue: {
      type: Number,
      required: false,
    },
  },
  data(): {
    isTooltipModalOpen: boolean,
    fieldId: string,
  } {
    return {
      isTooltipModalOpen: false,
      fieldId: `input-field-${getId()}`,
    };
  },
  computed: {
    valueSymbol(): string|null {
      if (this.type === InputFieldTypes.Percent) {
        return '%';
      } else if (this.type === InputFieldTypes.Currency) {
        return '$';
      }
      return null;
    },
    hasTip() {
      return typeof this.tip !== 'undefined';
    },
    displayValue: {
      get () {
        if (this.value && this.type === InputFieldTypes.Currency) {
          return this.formatCurrency(this.value);
        } else if (this.value && this.type === InputFieldTypes.Decimal) {
          return this.formatDecimal(this.value);
        } else if (this.value && this.type === InputFieldTypes.Percent) {
          return this.formatPercentage(this.value);
        }
        if (this.interactive) {
          return '';
        } else {
          return 0;
        }
      },
      set (value: string) {
        let parsedValue = parseFloat(value.replace(/,/g, ''));
        if (this.type === InputFieldTypes.Percent) {
          parsedValue = parsedValue / 100;
        }

        this.$emit('change', parsedValue);
      },
    },
  },
  methods: {
    validateNumber(event) {
      // Only allow numerals
      if (!/\d/.test(event.key) && event.key !== '.') {
        return event.preventDefault();
      }

      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;
      const currentValue = event.target.value.replace(/,/g, '');

      let parsedValue = 0;

      // Check against the new value
      if (selectionStart !== selectionEnd) {
        let keyIsGlued = false;

        parsedValue = currentValue.split('').map((element, index) => {
          if (index >= selectionStart && index <= selectionEnd) {
            if (keyIsGlued) {
              return '';
            }

            keyIsGlued = true;
            return event.key;
          }

          return element;
        }).join('');
      } else {
        parsedValue = parseFloat(currentValue + event.key);
      }

      // For percentages, adjust the maxValue when comparing it with the input
      const maxValue = (this.type === InputFieldTypes.Percent)? this.maxValue * 100 : this.maxValue;

      if (maxValue && parsedValue > maxValue) {
        return event.preventDefault();
      }

      if (this.maxDigits && parsedValue.toString().length >= this.maxDigits) {
        return event.preventDefault();
      }
    },
  },
});
