















import Vue from 'vue';

export default Vue.extend({
  methods: {
    close() {
      this.$emit('close');
    },
    handleKeyup(event: KeyboardEvent) {
      if (event.defaultPrevented) {
        return;
      }

      if (event.altKey || event.shiftKey || event.ctrlKey || event.metaKey) {
        return;
      }

      if (
        event.code === 'Escape' ||
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27
      ) {
        event.preventDefault();
        this.close();
      }
    },
  },
  beforeMount() {
    document.body.classList.add('u-overflow-hidden');
  },
  mounted() {
    document.addEventListener('keyup', this.handleKeyup);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyup);
    document.body.classList.remove('u-overflow-hidden');
  },
});
