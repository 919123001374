/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-googleplus': {
    width: 24,
    height: 15.91,
    viewBox: '0 0 24 15.91',
    data: '<path pid="0" d="M8 6.81v2.71h4.55c-.67 3.36-5.24 4.45-7.66 2.26a5.1 5.1 0 01.21-7.94 5.27 5.27 0 016.19.16 28.33 28.33 0 002-2.08A8.09 8.09 0 008 0a8 8 0 00-2.93 15.37c3.1 1.19 7.07.38 9-2.4a9.11 9.11 0 001.44-6.15C13 6.8 10.49 6.8 8 6.81zM21.73 6.8V4.53h-2.28V6.8h-2.28v2.25h2.28v2.26h2.26V9.04h2.27V6.82z"/>'
  }
})
